<template>
  <div>
    <van-nav-bar left-arrow left-text="返回" @click-left="goBack" title="查看全部网点" />
    <!-- <input type="date"> -->
    <div style="padding:6px 16px 0px;background-color:#fff;margin-top:17px">
      <div class="tit">当月师傅报完工工单量: {{gdl}}</div>
      <div class="tit">当月师傅报完工设备台数: {{sbts}}</div>
    </div>
    <van-cell title="选择日期" :value="date" @click="show = true" is-link />
    <van-popup v-model="show" safe-area-inset-bottom position="bottom">
      <van-datetime-picker v-model="currentDate" type="year-month" title :formatter="formatter" @cancel="show=false" @confirm="onConfirm" />
    </van-popup>
    <div style="margin-top:18px;background-color:#fff;padding-top:6px">
      <van-row style="padding:0 16px">
        <van-col span="2">
          <span class="listTitle">排名</span>
        </van-col>
        <van-col span="12">
          <span class="listTitle">网点</span>
        </van-col>
        <van-col span="5" @click="insFn">
          <span class="listTitle">
            工单量
            <van-icon :name="isIns?'arrow-down':'arrow-up'" />
          </span>
        </van-col>
        <van-col span="5" @click="repairFn">
          <span class="listTitle">
            设备条数
            <van-icon :name="isrepair?'arrow-down':'arrow-up'" />
          </span>
        </van-col>
      </van-row>
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
          <van-cell @click="websiteMonthFn(item.afterNumber)" v-for="(item,i) in dataList" :key="i">
            <van-row>
              <van-col span="2">{{i+1}}</van-col>
              <van-col span="12">
                <div class="listTitle">{{item.name}}</div>
              </van-col>
              <van-col span="5">
                <div style="text-align:right">
                  <div class="fr">{{item.orderct}}</div>
                </div>
              </van-col>
              <van-col span="5">
                <div style="text-align:right">
                  <div class="fr">{{item.machinect}}</div>
                </div>
              </van-col>
            </van-row>
            <van-row>
              <van-col span="2"></van-col>
              <van-col span="12">
                <div class="listTitle">{{item.afterNumber}}</div>
              </van-col>
              <van-col span="5">
                <div style="text-align:right">
                  <div v-if="item.sygdl>0" class="upFloat">
                    <img style="width:12px;height:12px" src="../../assets/icon/shangsheng.png" alt />
                    {{item.sygdl}}%
                  </div>
                  <div v-if="item.sygdl<0" class="downFloat">
                    <img style="width:12px;height:12px" src="../../assets/icon/down.png" alt />
                    {{-item.sygdl}}%
                  </div>
                </div>
              </van-col>
              <van-col span="5">
                <div style="text-align:right">
                  <div v-if="item.sysbts>0" class="upFloat">
                    <img style="width:12px;height:12px" src="../../assets/icon/shangsheng.png" alt />
                    {{item.sysbts}}%
                  </div>
                  <div v-if="item.sysbts<0" class="downFloat">
                    <img style="width:12px;height:12px" src="../../assets/icon/down.png" alt />
                    {{-item.sysbts}}%
                  </div>
                </div>
              </van-col>
            </van-row>
          </van-cell>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import { getUrlKey } from '@/utils/common'
export default {
  data() {
    return {
      dataList: [],
      show: false,
      date: '',
      gdl: 0,
      sbts: 0,
      loading: false,
      finished: false,
      refreshing: false,
      isIns: false,
      isrepair: false,
      currentDate: new Date()
    }
  },
  created() {
    this.date = this.$route.query.time
    this.date = this.date.replace(/-/g, '/')
  },
  computed: {
    startDate() {
      let day = this.date.split('/')
      return day[0] + '/' + day[1] + '/01'
    },
    endDate() {
      let day = this.date.split('/')
      let days = this.getmonthday(day[0], day[1])
      return day[0] + '/' + day[1] + '/' + days
    }
  },
  methods: {
    //月报网点详情
    websiteMonthFn(value) {
      this.$router.push({
        path: '/masterFinished/repaireDetailWebsit',
        query: {
          website: value,
          startTime: this.startDate,
          endTime: this.endDate,
          type: 'month'
        }
      })
    },
    getmonthday(year, month) {
      let days = new Date(year, Number(month), 0).getDate()
      return days
    },
    //维修筛选
    repairFn() {
      this.isrepair = !this.isrepair
      if (this.isrepair) {
        this.dataList.sort((a, b) => {
          return b.machinect - a.machinect
        }) /* 从大到小 */
      } else {
        this.dataList.sort((a, b) => {
          return a.machinect - b.machinect
        }) /* 从小到大 */
      }
      // this.isrepair = false;
    },
    //安装筛选
    insFn() {
      this.isIns = !this.isIns
      if (this.isIns) {
        this.dataList.sort((a, b) => {
          return b.orderct - a.orderct
        }) /* 从大到小 */
      } else {
        this.dataList.sort((a, b) => {
          return a.orderct - b.orderct
        }) /* 从小到大 */
      }
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.onLoad()
    },
    onLoad() {
      this.getList()
    },
    //获取数据
    getList() {
      let params = {
        openid: getUrlKey('openid', window.location.href),
        moduleId: '111111',
        type: '家用',
        startDate: this.startDate,
        endDate: this.endDate,
        sumType: 1,
        sumMode: 'repair.comp',
        timeConditionType: 'month'
      }
      this.postRequest('/wechat-analysis/summarys', params).then(res => {
        console.log(res)
        let gdl = 0
        let sbts = 0
        res.data.forEach(v => {
          let sygdl = 0
          let sysbts = 0
          if (v.orderct && v.morder) {
            sygdl = (((v.orderct - v.morder) / v.morder) * 100).toFixed(2) * 1
          }
          if (v.machinect && v.mmachine) {
            sysbts = (((v.machinect - v.mmachine) / v.mmachine) * 100).toFixed(2) * 1
          }
          v.sygdl = sygdl
          v.sysbts = sysbts
          gdl = gdl + v.orderct
          sbts = sbts + v.machinect
        })
        this.gdl = gdl
        this.sbts = sbts
        if (this.refreshing) {
          this.dataList = []
          this.refreshing = false
        }
        if (res.data.length === 0) {
          this.dataList = []
          this.finished = true
        }
        this.dataList = res.data
        this.isIns = false
        this.insFn()
        this.loading = false
        if (this.dataList.length >= res.data.length) {
          this.finished = true
        }
      })
    },
    onConfirm(date) {
      this.show = false
      this.date = this.formatDate(date)
      this.getList()
    },
    //日期格式化
    formatDate(date) {
      return `${date.getFullYear()}/${date.getMonth() + 1 >= 10 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)}`
    },
    //日期格式化
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`
      } else if (type === 'month') {
        return `${val}月`
      }
      return val
    },
    goBack() {
      this.$router.back()
    }
  }
}
</script>

<style lang="scss" scoped>
.listTitle {
  font-weight: 700;
  font-size: 12px;
}
.tit {
  font-weight: 700;
  font-size: 14px;
}
.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>